import React, { useState } from "react";
import FilterContext from "./FilterContext";

const FilterProvider = (props) => {
  const [isChecked, setIsChecked] = useState(true);
  const [noPaquetes, setNoPaquetes] = useState(true);
  const [loaderStylingStay, setLoaderStylingStay] = useState(false);
  const [mostrarTerminos, setMostrarTerminos] = useState(false);
  const [pagar, setPagar] = useState(false);


const setState = () => {
  setIsChecked(!isChecked);
};

const setStatePaquetes = () => {
  setNoPaquetes(!isChecked);
};

const setLoaderStyling = (value) => {
  setLoaderStylingStay(value);
};

const setTerminos = (value) => {
  setMostrarTerminos(value);
};

const setPagarReserva = (value) => {
  setPagar(value);
};

  return (
    <FilterContext.Provider
      value={{
        ...props,
        state: isChecked,
        setState: setState,
        paquetes: noPaquetes,
        setPaquetes: setStatePaquetes,
        Loader: loaderStylingStay,
        setLoader: setLoaderStyling,
        mostrarTerminos: mostrarTerminos,
        setMostrarTerminos: setTerminos,
        pagar: pagar,
        setPagar: setPagarReserva
      }}
    >
      {props.children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;
